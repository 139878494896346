import React, { Component } from "react";

import { Dialog, Classes, ControlGroup, MenuItem, InputGroup } from "@blueprintjs/core";

import { Table, Pagination } from "semantic-ui-react";

import { MultiSelect } from "@blueprintjs/select";

import { DateInput } from "@blueprintjs/datetime";

class EventListDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: this.props.isDialogOpened,
      filterData: [],
      displayEvents: [],
      selectedStations: [],
      selectedStationCodes: [],
      selectedDate: null,
      endDate: null,
      stations: [],
      selectedCategorys: [],
      categorys: ["general", "security", "c-security", "delay"],
      selectedSentiments: [],
      selectedSentimentTexts: [],
      sentiments: [
        {"text":'Extremely Negative', "key": 'ExtremelyNegative'},
        {"text":'Very Negative', "key": 'VeryNegative'},
        {"text":'Fairly Negative', "key": 'FairlyNegative'},
        {"text":'Neutral', "key": 'Neutral'},
        {"text":'Fairly Positive', "key": 'FairlyPositive'},
        {"text":'Very Positive', "key": 'VeryPositive'},
        {"text":'Extremely Positive', "key": 'ExtremelyPositive'}
      ],
      totalCount: 0,
      activePage: 1,
    };
    // this.state is not able to update status immediately
    this.filterText = "";

    this.resultTime = 0;
    this.apiType = "";
    if ("News" === this.props.title) {
      this.apiType = process.env.REACT_APP_DOMAIN + "/api/v1/news/searchByConditions?";
    } else {
      this.apiType = process.env.REACT_APP_DOMAIN + "/api/v1/event/searchByConditions?";
    }
  }

  componentWillReceiveProps(nextProps) {

    var filterData = nextProps.allEvents;
    var selectedStations = [];
    var selectedStationCodes = [];

    if (
      nextProps.isDialogOpened &&
      nextProps.initStationCode !== null &&
      nextProps.initStationCode !== undefined
    ) {
      var station = nextProps.stations.find(
        (station) => station.Code === nextProps.initStationCode
      );

      selectedStations.push(station);
      selectedStationCodes.push(nextProps.initStationCode);

      this.doFilter(
        this.state.selectedDate,
        this.state.endDate,
        selectedStationCodes,
        selectedStations,
        this.filterText,
        this.state.selectedCategorys,
        this.state.selectedSentiments,
        this.state.activePage,
      );

    }else if (nextProps.isDialogOpened){
      console.log("open");
      this.doFilter(
        this.state.selectedDate,
        this.state.endDate,
        this.state.selectedStationCodes,
        this.state.selectedStations,
        this.filterText,
        this.state.selectedCategorys,
        this.state.selectedSentiments,
        this.state.activePage,
      );
    }
    

    this.setState({
      isOpen: nextProps.isDialogOpened,
      filterData: filterData,
      displayEvents: filterData.slice(0, 10),
      stations: nextProps.stations,
      selectedStations: selectedStations,
      selectedStationCodes: selectedStationCodes,
    });
  }

  handlePageChange(page) {
    // let startIndex = (page - 1) * 10;
    // this.setState({
    //   displayEvents: this.state.filterData.slice(startIndex, startIndex + 10),
    // });
    this.doFilter(
      this.state.selectedDate,
      this.state.endDate,
      this.state.selectedStationCodes,
      this.state.selectedStations,
      this.filterText,
      this.state.selectedCategorys,
      this.state.selectedSentiments,
      page,
    );
  }

  // ===== Stations =====

  onStationSelect = (station) => {
    var selectedIndex = this.state.selectedStations.indexOf(station);

    if (selectedIndex === -1) {
      this.state.selectedStations.push(station);
      this.state.selectedStationCodes.push(station.Code);
    } else {
      this.state.selectedStations.splice(selectedIndex, 1);
      this.state.selectedStationCodes.splice(selectedIndex, 1);
    }

    this.doFilter(
      this.state.selectedDate,
      this.state.endDate,
      this.state.selectedStationCodes,
      this.state.selectedStations,
      this.filterText,
      this.state.selectedCategorys,
      this.state.selectedSentiments,
      1,
    );
  };

  onTagRemove = (tag, index) => {
    this.state.selectedStations.splice(index, 1);
    this.state.selectedStationCodes.splice(index, 1);
    this.doFilter(
      this.state.selectedDate,
      this.state.endDate,
      this.state.selectedStationCodes,
      this.state.selectedStations,
      this.filterText,
      this.state.selectedCategorys,
      this.state.selectedSentiments,
      1,
    );
  };

  renderStation(station, { modifiers, handleClick }) {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={station.Code}
        onClick={handleClick}
        text={station.Code + "  " + station.Name}
        shouldDismissPopover={true}
      />
    );
  }

  renderTag(station) {
    return station.Name;
  }

  // ===== Category =====

  onCategorySelect = (category) => {
    var selectedIndex = this.state.selectedCategorys.indexOf(category);

    if (selectedIndex === -1) {
      this.state.selectedCategorys.push(category);
    } else {
      this.state.selectedCategorys.splice(selectedIndex, 1);
    }

    this.doFilter(
      this.state.selectedDate,
      this.state.endDate,
      this.state.selectedStationCodes,
      this.state.selectedStations,
      this.filterText,
      this.state.selectedCategorys,
      this.state.selectedSentiments,
      1,
    );
  };

  onCategoryTagRemove = (tag, index) => {
    this.state.selectedCategorys.splice(index, 1);
    this.doFilter(
      this.state.selectedDate,
      this.state.endDate,
      this.state.selectedStationCodes,
      this.state.selectedStations,
      this.filterText,
      this.state.selectedCategorys,
      this.state.selectedSentiments,
      1,
    );
  };

  renderCategory(category, { modifiers, handleClick }) {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={category}
        onClick={handleClick}
        text={category}
        shouldDismissPopover={true}
      />
    );
  }

  renderCategoryTag(category) {
    return category;
  }

  // ===== Sentiment =====
  onSentimentSelect = (sentiment) => {
    var selectedIndex = this.state.selectedSentiments.indexOf(sentiment.key);

    if (selectedIndex === -1) {
      this.state.selectedSentiments.push(sentiment.key);
      this.state.selectedSentimentTexts.push(sentiment.text);
    } else {
      this.state.selectedSentiments.splice(selectedIndex, 1);
      this.state.selectedSentimentTexts.splice(selectedIndex, 1);
    }

    this.doFilter(
      this.state.selectedDate,
      this.state.endDate,
      this.state.selectedStationCodes,
      this.state.selectedStations,
      this.filterText,
      this.state.selectedCategorys,
      this.state.selectedSentiments,
      1,
    );
  };

  onSentimentTagRemove = (tag, index) => {
    this.state.selectedSentiments.splice(index, 1);
    this.state.selectedSentimentTexts.splice(index, 1);
    this.doFilter(
      this.state.selectedDate,
      this.state.endDate,
      this.state.selectedStationCodes,
      this.state.selectedStations,
      this.filterText,
      this.state.selectedCategorys,
      this.state.selectedSentiments,
      1,
    );
  };

  renderSentiment(sentiment, { modifiers, handleClick }) {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        key={sentiment.key}
        onClick={handleClick}
        text={sentiment.text}
        shouldDismissPopover={true}
      />
    );
  }

  renderSentimentTag(sentiment) {
    return sentiment;
  }

  doFilter(date, endDate, selectedStationCodes, selectedStations, filterText, selectedCategorys, selectedSentiments, activePage) {

    var filterData;
    var self = this
    // NEW CODE
    var nowDate       = new Date();
    var fromDate       = new Date(date);
    var toDate       = endDate == null ? nowDate : new Date(endDate);
    var convertedFrom = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()  ).getTime();
    var convertedTo   = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate()+1).getTime();
    fetch(this.apiType + new URLSearchParams({
      from: convertedFrom,
      to: convertedTo,
      code: selectedStationCodes.join(" "),
      message: filterText.replaceAll(" ", "\\ "),
      category: selectedCategorys.join(" "),
      sentiment: selectedSentiments.join(" "),
      activePage: activePage
    })).then((response) => response.json())
       .then((data) => {
          if (this.resultTime < nowDate.getTime()) {
            this.resultTime = nowDate.getTime();

            if (data.events != null) {
              data.events.forEach( (event) => {
                if ("created_at" in event) {
                  event.date = new Date(parseInt(event.created_at));
                } else if ("publishedAt" in event) {
                  var date = event.publishedAt.replace('Z', '').replace('T', ' ');
                  event.date = new Date(date);
                  event.message = null;
                } else{
                  event.date = new Date(event.date);
                }
              });
            }else {
              data.events = [];
            }
            

            filterData = data.events;

            this.setState({
              filterData: filterData,
              displayEvents: filterData.slice(0, 10),
              selectedStations: selectedStations,
              selectedStationCodes: selectedStationCodes,
              selectedDate: date,
              endDate: endDate,
              totalCount: data.totalCount,
              activePage: activePage
            });

          }

        });

  }

  render() {
    let dialogStyle = {
      width: "80%",
    };

    let filterGroupStyle = {
      marginTop: 20,
      marginLeft: 20,
      marginRight: 20,
    };

    return (
      <Dialog
        icon={this.props.icon}
        title={this.props.title}
        isOpen={this.state.isOpen}
        usePortal={true}
        style={dialogStyle}
        onClose={() => {
          this.props.onClose();
          this.state.selectedStations = [];
          this.state.selectedStationCodes = [];
          this.state.selectedDate = null;
          this.state.endDate = null;
          this.filterText = "";
          this.state.selectedCategorys = [];
          this.state.selectedSentiments = [];
          this.state.selectedSentimentTexts = [];
          this.setState({
            filterData: this.props.allEvents,
            displayEvents: this.props.allEvents.slice(0, 10),
            selectedStations: [],
            selectedStationCodes: [],
            selectedDate: null,
            endDate: null,
            selectedCategorys: [],
            selectedSentiments: [],
            selectedSentimentTexts: [],
            activePage: 1,
          });
        }}
      >
        <div style={filterGroupStyle}>
          <ControlGroup>
            <DateInput
              formatDate={(date) => date.toLocaleDateString()}
              parseDate={(str) => new Date(str)}
              shortcuts={true}
              onChange={(date, isUserChange) => {
                if (!isUserChange) return;

                this.doFilter(
                  date,
                  this.state.endDate,
                  this.state.selectedStationCodes,
                  this.state.selectedStations,
                  this.filterText,
                  this.state.selectedCategorys,
                  this.state.selectedSentiments,
                  1,
                );
              }}
              placeholder="Filter by start date"
            />
            <DateInput
              formatDate={(date) => date.toLocaleDateString()}
              parseDate={(str) => new Date(str)}
              shortcuts={true}
              onChange={(date, isUserChange) => {
                if (!isUserChange) return;

                this.doFilter(
                  this.state.selectedDate,
                  date,
                  this.state.selectedStationCodes,
                  this.state.selectedStations,
                  this.filterText,
                  this.state.selectedCategorys,
                  this.state.selectedSentiments,
                  1,
                );
              }}
              placeholder="Filter by end date"
            />
            {"News" === this.props.title ?
              ""
              : 
              <MultiSelect
                itemRenderer={this.renderStation}
                items={this.state.stations}
                tagRenderer={this.renderTag}
                onItemSelect={this.onStationSelect}
                onRemove={this.onTagRemove}
                selectedItems={this.state.selectedStations}
                popoverProps={{ popoverClassName: "station-popover" }}
                placeholder="station"
              />
            }
            
            <MultiSelect
              itemRenderer={this.renderCategory}
              items={this.state.categorys}
              tagRenderer={this.renderCategoryTag}
              onItemSelect={this.onCategorySelect}
              onRemove={this.onCategoryTagRemove}
              selectedItems={this.state.selectedCategorys}
              popoverProps={{ popoverClassName: "station-popover" }}
              placeholder="category"
            />
            <MultiSelect
              itemRenderer={this.renderSentiment}
              items={this.state.sentiments}
              tagRenderer={this.renderSentimentTag}
              onItemSelect={this.onSentimentSelect}
              onRemove={this.onSentimentTagRemove}
              selectedItems={this.state.selectedSentimentTexts}
              popoverProps={{ popoverClassName: "station-popover" }}
              placeholder="sentiment"
            />
            <input type="text"
                   className="pt-input"
                   placeholder="Search by keyword"
                   onChange={(e) => {

                     this.filterText = e.target.value;

                     this.doFilter(
                         this.state.selectedDate,
                         this.state.endDate,
                         this.state.selectedStationCodes,
                         this.state.selectedStations,
                         e.target.value,
                         this.state.selectedCategorys,
                         this.state.selectedSentiments,
                         1,
                     );
                   }}
            />
          </ControlGroup>

        </div>
        <div className={Classes.DIALOG_BODY}>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Category</Table.HeaderCell>
                <Table.HeaderCell>Sentiment</Table.HeaderCell>
                <Table.HeaderCell>Text</Table.HeaderCell>
                <Table.HeaderCell>Link</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {this.state.displayEvents.map((e, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{e.date.toLocaleString("en-US")}</Table.Cell>
                  <Table.Cell>{e.label}</Table.Cell>
                  <Table.Cell>
                    {e.sentiment === null ? "Sentiment" : e.sentiment}
                  </Table.Cell>
                  <Table.Cell>
                    {e.message === null ? (
                      <div>
                        <b>{e.title}</b>
                        <div>{e.description}</div>
                      </div>
                    ) : (
                      <div>{e.message}</div>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {e.url === null ? (
                      ""
                    ) : (
                      <a href={e.url} rel="noopener noreferrer" target="_blank">
                        {" "}
                        Source
                      </a>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>

            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan="4">
                  <Pagination
                    activePage={this.state.activePage}
                    floated="right"
                    onPageChange={(e, { activePage }) => {
                      this.handlePageChange(activePage);
                    }}
                    totalPages={Math.floor(this.state.totalCount / 10)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </div>
      </Dialog>
    );
  }
}

export default EventListDialog;
