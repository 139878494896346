import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import Fab from '@material-ui/core/Fab';

export default class UserFeedback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      category: 0,
    };
  }

  render() {
    const style = {
      position: "fixed",
      top: "0px",
      bottom: "0px",
      right: "0px",
      left: "0px",
      width: "100%",
      height: this.props.height,
      backgroundColor: "#FFFFFF",
      display: "flex",
      flexWrap: "wrap",
    };

    const dropdown = {
      margin: 16,
      minWidth: 120,
    };

    const btn = {
      position: "fixed",
      right: 16,
      bottom: 72,

    };

    return (
      <div style={style}>
        <div>
          <TextField
            label="Title"
            style={{ margin: 16, width: "90%" }}
            // placeholder="Event title"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Date"
            style={{ margin: 16, width: "90%" }}
            // placeholder="Event title"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            label="Station"
            style={{ margin: 16, width: "90%" }}
            // placeholder="Event title"
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <FormControl style={dropdown}>
            <InputLabel id="demo-simple-select-label">Category</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.state.category}
              onChange={(event) =>
                this.setState({
                  category: event.target.value,
                })
              }
            >
              <MenuItem value={0}>general</MenuItem>
              <MenuItem value={1}>delay</MenuItem>
              <MenuItem value={2}>security</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Description"
            style={{ margin: 16, width: "90%" }}
            multiline
            rowsMax={10}
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <Fab style={btn}>
            <SendIcon />
          </Fab>
          {/* <Button
            style={btn}
            variant="contained"
            color="default"
            endIcon={<SendIcon />}
          >
            Send
          </Button> */}
        </div>
      </div>
    );
  }
}
