import React, { Component } from "react";
import { Feed } from "semantic-ui-react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";

export default class MobileEventList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allEvents: props.allEvents,
      filterData: props.allEvents.slice(0, 499),
      selectedDate: new Date(),
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps: " + nextProps);
    this.setState({
      allEvents: nextProps.allEvents,
      filterData: nextProps.allEvents.slice(0, 499),
      isFilterOpen: false,
    });
  }

  getEventByStation() {
    const feedStyle = {
      padding: "10px",
    };

    return this.state.filterData.map((story) => (
      <Feed.Event style={feedStyle}>
        <Feed.Content>
          <Feed.Date>{story.date.toLocaleDateString()}</Feed.Date>
          <Feed.Extra text>
            {story.message}
            {story.url === null ? (
              ""
            ) : (
              <a href={story.url} rel="noopener noreferrer" target="_blank">
                {" "}
                Source
              </a>
            )}
          </Feed.Extra>
        </Feed.Content>
      </Feed.Event>
    ));
  }

  handleClose = () => {
    const filterData = this.props.allEvents
      .filter((a) => {
        return (
          a.date.toLocaleDateString() ===
          this.state.selectedDate.toLocaleDateString()
        );
      })
      .slice(0, 499);

    this.setState({
      isFilterOpen: false,
      filterData: filterData,
    });
  };

  handleDateChange = (date) => {
    this.setState({
      selectedDate: date,
    });
  };

  render() {
    const timelineStyle = {
      position: "fixed",
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      zIndex: "100",
      width: "100%",
      margin: "0px",
      backgroundColor: "#FFFFFF",
      overflowY: "scroll",
      overflowX: "hidden",
      marginBottom: "56px",
      display: "flex",
      flexWrap: "wrap",
    };

    const list = {
      padding: "10px",
      height: this.props.height,
    };

    const bar = {
      width: "100%",
      flexGrow: 1,
    };

    const title = {
      flexGrow: 1,
    };

    return (
      <div style={timelineStyle}>
        <AppBar position="static" color="inherit" style={bar}>
          <Toolbar>
            <Typography variant="h6" style={title}>
              Events
            </Typography>
            <Button
              onClick={() => {
                this.setState({
                  isFilterOpen: true,
                });
              }}
              color="inherit"
            >
              filter
            </Button>
          </Toolbar>
        </AppBar>

        <div style={list}>
          <Feed select id="feed">
            {this.getEventByStation()}
          </Feed>
        </div>

        <Dialog
          open={this.state.isFilterOpen}
          onClose={this.handleClose}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">Filter</DialogTitle>
          <DialogContent dividers={true}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Date"
                  format="MM/dd/yyyy"
                  value={this.state.selectedDate}
                  onChange={this.handleDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Apply
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
