import React, { Fragment, Component } from "react";
import twitter from "./ic_twitter.svg";
import roadwork from "./road-work.svg";
import event from "./event.svg";
import roadclosure from "./road-closure.svg";
import accident from "./accident.png";
import hazards from "./hazards.png";
import metro from "./metro.png";
import metroThreat from "./metro_threat.png";
import metroThreatOrange from "./metro_threat_orange.png"
import isMobile from "ismobilejs";

const InfoWindow = (props) => {
  const { event, recentEventCount } = props;
  const infoWindowStyle = {
    display: "flex",
    position: "relative",
    left: -125,
    borderRadius: "5px",
    height: 150,
    width: 250,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
    overflowY: "scroll",
    padding: 10,
    paddingBottom: 15,
    zIndex: 100000000,
  };

  let msg =
    recentEventCount > 0
      ? `${recentEventCount}  events that took place around ${event.Name} this past week`
      : "Nothing happened this past week";

  return (
    <div style={infoWindowStyle}>
      <div className="ui items">
        <div className="item">
          <div className="content">
            <a className="header">{event.Name}</a>
            <div className="description">{msg}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default class StationMarker extends Component {
  isPWA() {
    return window.matchMedia("(display-mode: standalone)").matches;
  }

  showInfoWindow() {
    if (!this.props.event.visible) return false;
    return isMobile(navigator.userAgent).any
      ? false
      : this.props.event.show || this.props.event.lock;
  }

  getIcon() {
    switch (this.props.event.category) {
      case "Metro":
        if(this.getRecentEventCountByDays(3) > 0){
          return metroThreat  // events happened in 3 days
        }else if(this.getRecentEventCountByDays(7) > 0){
          return metroThreatOrange     // events happened in 7 days
        }else{
          return metro;
        }
      case "Roadwork/Construction":
        return roadwork;
      case "Event":
        return event;
      case "Road Closure":
        return roadclosure;
      case "Incident/Accident":
        return accident;
      case "Hazards":
        return hazards;
      default:
        return twitter;
    }
  }

  getRecentEventCountByDays(days){
    let today = new Date();
    let preDay = new Date(today.getTime() - days * 24 * 60 * 60 * 1000)
    return this.props.event.stories.filter((item) => {
      return (
          item &&
          item.date &&
          typeof(item.date.getDate) === 'function' &&
          item.date.getTime() >= preDay.getTime() &&
          item.date.getTime() <= today.getTime()
      );
    }).length;
  }

  getRecentEventCount() {
    let today = new Date();
    let lastWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);

    return this.props.event.stories.filter((item) => {
      return (
        item.date.getTime() >= lastWeek.getTime() &&
        item.date.getTime() <= today.getTime()
      );
    }).length;
  }

  render() {
    const markerStyle = {
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
    };

    const imageStyle = {
      visibility: "visible",
      width: "32px",
      height: "32px",
    };

    const imageHoverStyle = {
      visibility: "visible",
      width: "48px",
      height: "48px",
    };

    const invisibleStyle = {
      visibility: "hidden",
    };

    const markerstyle = !this.props.event.visible
      ? invisibleStyle
      : this.props.$hover || this.props.event.forceHover
      ? imageHoverStyle
      : imageStyle;

    return (
      <Fragment>
        <div style={markerStyle}>
          <img
            style={markerstyle}
            src={this.getIcon()}
            className="maker"
            alt="marker"
          />
        </div>
        {this.showInfoWindow() && (
          <InfoWindow
            event={this.props.event}
            recentEventCount={this.getRecentEventCount()}
          />
        )}
      </Fragment>
    );
  }
}
