import classNames from "classnames";
import React, { Component } from "react";

import { Dialog, Classes, ControlGroup, HTMLSelect } from "@blueprintjs/core";

import { DateInput } from "@blueprintjs/datetime";

export default class StoryDialog extends Component {
  constructor(props) {
    super(props);

    this.apiType = "";
    if ("Storytelling" === this.props.title) {
      this.apiType = process.env.REACT_APP_DOMAIN + "/api/v1/story?";
    } else {
      this.apiType = process.env.REACT_APP_DOMAIN + "/api/v1/news/news_story?";
    }

    let currDate = new Date();
    currDate.setDate(currDate.getDate() - 1);

    this.state = {
      isOpen: this.props.isDialogOpened,
      selectedIndex: 0,
      stories: [],
      allTimeline: {},
      displayTimeline: {},
      selectedDate: currDate,
      isInit: false,
      // timeRangeSelection: "week",
      timeRangeSelection: "day",
    };
  }

  componentWillReceiveProps(nextProps) {
    // let allTimelineByRange =
    //   nextProps.allTimeline[this.state.timeRangeSelection];
    
    // let stories, displayTimeline;
    // if (allTimelineByRange !== undefined) {

    //   stories =
    //     allTimelineByRange.length === 0 ? null : allTimelineByRange[0].stories;
    //   displayTimeline =
    //     stories === null || stories.length === 0 ? null : stories[this.state.selectedIndex].timeline;
    // } else {
    //   stories = null;
    //   displayTimeline = null;
    // }

    // this.setState({
    //   isOpen: nextProps.isDialogOpened,
    //   allTimeline: nextProps.allTimeline,
    //   stories: stories,
    //   displayTimeline: displayTimeline,
    // });

    if (nextProps.isDialogOpened && this.state.isOpen === false) {
      let currDate = new Date();
      currDate.setDate(currDate.getDate() - 1);
      this.doFilter(currDate, this.state.timeRangeSelection);
    }
    

    this.setState({
      isOpen: nextProps.isDialogOpened,
    });
  }

  componentDidUpdate() {
    if (
      this.state.isInit &&
      this.state.isOpen &&
      this.state.displayTimeline != null
    ) {
      if (this.state.displayTimeline === null) {
        window.timeline = null;
      } else {
        window.timeline = new window.TL.Timeline(
          "timeline-embed",
          this.state.displayTimeline,
        );
      }
    }
  }

  getDate(date) {
    let month = parseInt(date.getMonth()) + 1;
    let selectDate = date.getFullYear() + '-' + month.toString() + '-' + date.getDate();
    return selectDate;
  }

  getNextDate(date) {
    let month = parseInt(date.getMonth()) + 1;
    let selectDate = date.getFullYear() + '-' + month.toString() + '-' + (date.getDate()+1);
    return selectDate;
  }

  doFilter(selectedDate, timeRangeSelection) {
    // var selectedTimeline =
    //   this.state.allTimeline[timeRangeSelection];

    var date = selectedDate == null ? new Date() : new Date(selectedDate);
    var fromDate = new Date(this.getDate(date)).getTime();
    var toDate   = new Date(this.getNextDate(date)).getTime();

    
    // if (selectedTimeline.length !== 0){
    //   if (date === null) {
    //     stories = this.state.allTimeline[timeRangeSelection];
    //   } else {
    //     date = this.getDate(date);
    //     stories = this.state.allTimeline[timeRangeSelection].filter((a) => {
    //       return a.date === date;
    //     });
    //   }

    //   if (stories.length !== 0 && stories[0].stories.length !== 0) {
    //     stories = stories[0].stories;
    //     timeline = stories[0].timeline;
    //   } else {
    //     timeline = null;
    //     if(this.state.isOpen){
    //       alert("No story for the chosen case, please choose another day.")
    //     }
    //   }
    // } else {
    //   stories = null;
    //   timeline = null;
    //   if(this.state.isOpen){
    //     alert("No story for the chosen case, please choose another day.")
    //   }
    // }

    var stories;
    var timeline;
    fetch(this.apiType + "from=" + fromDate + "&to=" + toDate + "&type=" + timeRangeSelection)
        .then((response) => response.json())
        .then((data) => {

          // week or day
          stories = data[timeRangeSelection];

          if (stories.length > 0) {
            stories = stories[0].stories;
            timeline = stories[0].timeline;
          }else{
            stories = null;
            timeline = null;
            if(this.state.isOpen){
              alert("No story for the chosen case, please choose another day.")
            }
          }
          
          this.setState({
            timeRangeSelection: timeRangeSelection,
            selectedDate: selectedDate,
            stories: stories,
            selectedIndex: 0,
            displayTimeline: timeline,
          });

    });
  }

  handleClick = (timeline) => {
    let index = parseInt(timeline.currentTarget.id);
    this.setState({
      selectedIndex: index,
      displayTimeline: this.state.stories[index].timeline,
    });
  };

  renderDialogStep = (story, index) => {
    const currentlySelected = this.state.selectedIndex === index;
    
    return (
      <div
        className={classNames(Classes.DIALOG_STEP_CONTAINER, {
          [Classes.ACTIVE]: currentlySelected,
          [Classes.DIALOG_STEP_VIEWED]: true,
        })}
        key={index}
      >
        <div
          className={Classes.DIALOG_STEP}
          onClick={this.handleClick}
          id={index}
        >
          <div className={Classes.DIALOG_STEP_TITLE}>{story.title}</div>
        </div>
      </div>
    );
  };

  renderLeftPanel() {
    return (
      <div className={Classes.MULTISTEP_DIALOG_LEFT_PANEL}>
        {this.state.stories === null
          ? null
          : this.state.stories.map(this.renderDialogStep)}
      </div>
    );
  }

  renderRightPanel() {
    var style = {
      width: "80%",
      height: "500px",
    };

    return (
      this.state.displayTimeline !== null && (
        <div id="timeline-embed" style={style}></div>
      )
    );
  }

  render() {
    let dialogStyle = {
      width: "80%",
    };

    let filterGroupStyle = {
      padding: 10,
      paddingLeft: 20,
      backgroundColor: "#FFFFFF",
    };
    const FILTER_OPTIONS = ["day"];

    return (
      <Dialog
        icon={this.props.icon}
        title="Storytelling"
        isOpen={this.state.isOpen}
        usePortal={true}
        style={dialogStyle}
        onClose={() => {
          this.setState({ selectedDate: null });
          this.props.onClose();
        }}
        onOpened={() => {
          this.setState({ isInit: true });
        }}
      >
        <div style={filterGroupStyle}>
          <ControlGroup>
            <DateInput
              value={this.state.selectedDate}
              formatDate={(date) => date.toLocaleDateString()}
              parseDate={(str) => new Date(str)}
              shortcuts={true}
              onChange={(date, isUserChange) => {
                if (!isUserChange) return;
                this.doFilter(date, this.state.timeRangeSelection);
              }}
              placeholder="Filter by date"
            />
            <HTMLSelect
              options={FILTER_OPTIONS}
              value={this.state.timeRangeSelection}
              onChange={(event) => {
                this.doFilter(this.state.selectedDate, event.currentTarget.value);
              }}
            />
          </ControlGroup>
        </div>
        <div className={Classes.MULTISTEP_DIALOG_PANELS}>
          {this.renderLeftPanel()}
          {this.renderRightPanel()}
        </div>
      </Dialog>
    );
  }
}
