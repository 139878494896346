import React, { Component } from "react";
import './commonComponent/css/LoginPage.css'
import {Dialog} from "@blueprintjs/core";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            isOpen: this.props.isDialogOpened,
            isInit: false,
            username: '',
            password: '',
            msg: '',
        };

        this.apiType = process.env.REACT_APP_DOMAIN + "/api/v1/login?";
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
        // Open
        if (this.state.isOpen === false && this.props.isDialogOpened) {
            this.setState({
                isOpen : true
            })
            return;
        }

        // Close
        if (this.state.isOpen && this.props.isDialogOpened === false) {
            this.setState({
                isOpen : false,
                msg: '',
                username: '',
                password: ''
            })
            return;
        }
        

        if (
            this.state.isInit &&
            this.state.isOpen
        ) {
            // const tags = [];

            // fetch(process.env.REACT_APP_DOMAIN + "/api/v1/keyword/")
            //     .then((response) => response.json())
            //     .then((data) => {
            //         data.keywords.forEach( (keyword) => {
            //             tags.push(keyword.keyword);
            //         });

            //         console.log("TAGS: " + tags);

            //         this.setState({
            //             isInit : false,
            //             tags : tags
            //         });
            //     });
        }
    }


    login(username, password) {
        const data = {
            username: username,
            password: password,
        }
        fetch(this.apiType, {
            method: "post",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then((response) => response.json())
           .then((data) => {
                if (data.status == 'ok') {
                    this.props.setLogin();
                    this.props.onClose();
                }else {
                    this.setState({
                        password: '',
                        msg: 'username/password is wrong'
                    })
                }
            }).catch(err=>{
                console.log(err)
            });
    }


    render() {
        let dialogStyle = {
            width: "80%",
        };

        return (
            <Dialog
                icon={this.props.icon}
                title={this.props.title}
                isOpen={this.state.isOpen}
                usePortal={true}
                style={dialogStyle}
                onClose={() => {
                    this.props.onClose();
                }}
                onOpened={() => {
                    this.setState({ isInit: true });
                }} 
                className="login-dialog"
            >
                <div className="login-box">
                    <div className="login-logo" >
                        <h1>Login</h1>
                    </div>
                    <div className="login-form">
                        <Input type='text' name='username' placeholder='username' value={this.state.username} onChange={e => this.setState({username: e.target.value})}/>
                        <Input type='password' name='password' placeholder='password' value={this.state.password} onChange={e => this.setState({password: e.target.value})}/>
                        <button onClick={() => {
                            this.login(this.state.username, this.state.password);
                        }}>Sign In</button>
                    </div>
                    <strong className='login-msg'>{this.state.msg}</strong>
                </div>
            </Dialog>
        );
    }
}

class Input extends React.Component {
  render() {
    return <div className='Input'>
              <input type={ this.props.type } 
                     name={ this.props.name } 
                     placeholder={ this.props.placeholder } 
                     required 
                     autoComplete='false'
                     onChange={ this.props.onChange }
                     value={this.props.value}/>
           </div>
  }

}

export default LoginPage;
