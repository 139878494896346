import React, { Component } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import {
  LocationOn,
  Announcement,
  ListAlt,
  DirectionsTransit,
  Description,
} from "@material-ui/icons";

import {
  Alignment,
  Button,
  Navbar,
  NavbarDivider,
  NavbarGroup,
  NavbarHeading,
  Drawer,
  Position,
  Tree,
  Toaster,
} from "@blueprintjs/core";

import isMobile from "ismobilejs";
import _ from "lodash";
import IncidentMap from "./IncidentMap";
import MobileStationList from "./MobileStationList";
import EventListDialog from "./EventListDialog";
import NewsPanel from "./NewsPanel";
import RealtimeMonitor from "./RealtimeMonitor";
import MobileEventList from "./MobileEventList";
import MobileNewsList from "./MobileNewsList";
import StationInfo from "./StationInfo";
import UserFeedback from "./UserFeedback";
import StoryDialog from "./StoryDialog";
import KeywordList from "./KeywordList";
import LoginPage from "./LoginPage";


class MainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stations: [],
      allTimeline: {}, // twitter storytelling
      timeline: [], // twitter storytelling
      allStoryNewsTimeline:{}, // news storytelling
      storyNewsTimeline: [], // news storytelling
      lineNodes: {},
      mobileShowStationList: false,
      mobileShowIncidentList: false,
      selectedStation: null,
      isDrawerOpened: false,
      isMonitorOpened: true,
      isDialogOpened: false,
      isNewsOpened: false,
      isStorytellingOpened: false,
      isStorytellingOpenedNews: false,
      isKeywordListOpened:false,
      isLoginPageOpened: false,
      allEvents: [],
      allNews: [],
      filterData: [],
      displayEvents: [],
      mobilePage: 0,
      stationInfoOpen: false,
      mapHeight: window.innerHeight,
      isUserLogin: false,
    };
    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onStationClick = this.onStationClick.bind(this);
    this.child = React.createRef();
  }

  toaster;
  refHandlers = {
    toaster: (ref) => (this.toaster = ref),
  };

  componentDidMount() {
    fetch(process.env.REACT_APP_DOMAIN + "/api/v1/station")
      .then((response) => response.json())
      .then((data) => {
        var lines = {};
        data.Stations.forEach((result) => {
          result.show = false;
          result.lock = false;
          result.visible = true;
          result.forceHover = false;
          result.category = "Metro";

          result.stories.forEach((story) => {
              if ( "create_at" in story) {
                story.date = new Date(parseInt(story.created_at));
              } else {
                story.date = new Date(parseInt(story.date));
              }
            });
            result.stories = result.stories.reverse();
         


          if (result.LineCode1 != null) {
            this.addToLineMap(lines, result.LineCode1, result);
          }

          if (result.LineCode2 != null) {
            this.addToLineMap(lines, result.LineCode2, result);
          }

          if (result.LineCode3 != null) {
            this.addToLineMap(lines, result.LineCode3, result);
          }

          if (result.LineCode4 != null) {
            this.addToLineMap(lines, result.LineCode4, result);
          }
        });

        this.setState({
          stations: data.Stations,
          lineNodes: this.createLineNodes(lines),
        });
      });

    fetch(process.env.REACT_APP_DOMAIN + "/api/v1/event")
      .then((response) => response.json())
      .then((data) => {
        data.events.forEach((event) => {
          if ("created_at" in event) {
            event.date = new Date(parseInt(event.created_at));
          } else {
            event.date = new Date(event.date);
          }
        });
        let allEvents = data.events;
        this.setState({
          allEvents: allEvents,
          filterData: allEvents,
          displayEvents: allEvents.slice(0, 10),
        });
      });

    fetch(process.env.REACT_APP_DOMAIN + "/api/v1/news")
      .then((response) => response.json())
      .then((data) => {
        data.news.forEach((news) => {
          // TODO: label (category)
          if (news.publishedAt != null) {
            news.date = new Date(news.publishedAt);
          } else {
            news.date = new Date(0);
          }
          news.message = null;
        });

        this.setState({ allNews: data.news });
      });

    if (isMobile(navigator.userAgent).any) {
      this.setState({
        mapHeight:
          window.innerHeight - document.getElementById("nav").clientHeight,
      });
    }

    fetch(process.env.REACT_APP_DOMAIN + "/api/v1/login/isLogin")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 'ok') {
          this.setState({isUserLogin: true})
        }else {
          this.setState({isUserLogin: false})
        }
    });

    // fetch(process.env.REACT_APP_DOMAIN + "/api/v1/story")
    //   .then((response) => response.json())
    //   .then((data) => {
    //     this.setState({
    //       timeline: data,
    //       allTimeline: data,
    //     });
    //   });

    // fetch(process.env.REACT_APP_DOMAIN + "/api/v1/news/news_story")
    //     .then((response) => response.json())
    //     .then((data) => {
    //       this.setState({
    //         storyNewsTimeline: data,
    //         allStoryNewsTimeline: data,
    //       });
    //     });
  }

  addToLineMap(lines, lineCode, station) {
    if (lines[lineCode] === undefined) {
      lines[lineCode] = [];
    }

    lines[lineCode].push(station);
  }

  createLineNodes(lines) {
    var lineNodes = [];
    Object.keys(lines).forEach((lineCode) => {
      var line = {
        id: lineNodes.length,
        hasCaret: true,
        label: lineCode,
        childNodes: lines[lineCode].map((station) => {
          return {
            id: station.Code,
            icon: "train",
            label: station.Name,
          };
        }),
      };

      lineNodes.push(line);
    });
    return lineNodes;
  }

  handleNodeClick = (nodeData, _nodePath, e) => {
    if (nodeData.hasCaret) {
      return;
    }

    const originallySelected = nodeData.isSelected;
    if (!e.shiftKey) {
      this.forEachNode(this.state.lineNodes, (n) => (n.isSelected = false));
    }
    nodeData.isSelected =
      originallySelected == null ? true : !originallySelected;

    this.setState({ isDrawerOpened: false });
    this.onStationClick(nodeData.id);
  };

  handleNodeCollapse = (nodeData) => {
    nodeData.isExpanded = false;
    this.setState(this.state);
  };

  handleNodeExpand = (nodeData) => {
    nodeData.isExpanded = true;
    this.setState(this.state);
  };

  forEachNode(nodes, callback) {
    if (nodes == null) {
      return;
    }

    for (const node of nodes) {
      callback(node);
      this.forEachNode(node.childNodes, callback);
    }
  }

  onStationListClick = () => {
    this.setState({ mobileShowStationList: true });
  };

  onIncidentListClick = () => {
    this.setState({ mobileShowIncidentList: true });
  };

  onListClose = () => {
    this.setState({
      mobileShowStationList: false,
      mobileShowIncidentList: false,
    });
  };

  onStationClick(stationId, moveToStation = true) {
    if (isMobile(navigator.userAgent).any) {
      this.setState({
        selectedStation: stationId,
        mobilePage: 0,
        stationInfoOpen: true,
      });
    } else {
      this.setState({
        selectedStation: stationId,
        isDialogOpened: true,
      });
    }

    if (moveToStation) {
      let station = _.find(this.state.stations, { Code: stationId });
      this.setState({
        mapUpdate: true,
        newCenter: {
          Lat: station.Lat,
          Lon: station.Lon,
          lat: station.Lat,
          lng: station.Lon,
        },
      });
    }
  }

  onMarkerClick(id) {
    this.setState((state) => {
      state.stations.forEach((station) => {
        station.lock = station.id === id ? !station.lock : false;
        station.show = station.id === id ? !station.show : false;
        station.forceHover = station.id === id ? !station.forceHover : false;
      });
      return { mobileShowIncidentList: false, stations: state.stations };
    });
  }

  getFormatedDate(event) {
    if ("created_at" in event) {
      return new Date(parseInt(event.created_at)).toLocaleString("en-US");
    } else {
      return event.date;
    }
  }

  render() {
    const drawerState = {
      autoFocus: true,
      canEscapeKeyClose: true,
      canOutsideClickClose: true,
      enforceFocus: true,
      hasBackdrop: true,
      isOpen: this.state.isDrawerOpened,
      position: Position.RIGHT,
      size: Drawer.SIZE_SMALL,
      usePortal: true,
    };

    const drawerStyle = {
      overflowY: "auto",
      overflowX: "hidden",
    };

    const layout = (
      <div>
        <IncidentMap
          style={{ width: "100%", height: 0 }}
          stations={this.state.stations}
          onStationClick={this.onStationClick}
          newCenter={this.state.newCenter}
          onUpdate={(events) => this.setState({ center: events })}
        />

        <EventListDialog
          icon="info-sign"
          title="All events"
          isDialogOpened={this.state.isDialogOpened}
          allEvents={this.state.allEvents}
          onClose={() =>
            this.setState({ isDialogOpened: false, selectedStation: null })
          }
          stations={this.state.stations}
          initStationCode={this.state.selectedStation}
        />

        <EventListDialog
          icon="application"
          title="News"
          isDialogOpened={this.state.isNewsOpened}
          allEvents={this.state.allNews}
          onClose={() => this.setState({ isNewsOpened: false })}
          stations={this.state.stations}
        />

        <StoryDialog
          title="Storytelling"
          isDialogOpened={this.state.isStorytellingOpened}
          allTimeline={this.state.allTimeline}
          onClose={() => this.setState({ isStorytellingOpened: false })}
        />

        <StoryDialog
            title="Newstelling"
            isDialogOpened={this.state.isStorytellingOpenedNews}
            allTimeline={this.state.allStoryNewsTimeline}
            onClose={() => this.setState({ isStorytellingOpenedNews: false })}
        />

        <KeywordList
          isDialogOpened={this.state.isKeywordListOpened}
          onClose={() => this.setState({ isKeywordListOpened: false })}
          title="Keyword List of Emergency Email Notification"
        />

        <LoginPage
          isDialogOpened={this.state.isLoginPageOpened}
          onClose={() => this.setState({ isLoginPageOpened: false })}
          setLogin={() => this.setState({isUserLogin: true})}
          title="Login Page"
        />

        <Navbar>
          <NavbarGroup align={Alignment.LEFT}>
            <NavbarHeading>WMATA</NavbarHeading>
            <NavbarDivider />
            <Button
              className="bp3-minimal"
              icon="desktop"
              text="Real-time Incidents"
              onClick={() =>
                this.setState({ isMonitorOpened: !this.state.isMonitorOpened })
              }
            />

            <Button
              className="bp3-minimal"
              icon="panel-table"
              text="Tweet History"
              onClick={() => this.setState({ isDialogOpened: true })}
            />

            <Button
              className="bp3-minimal"
              icon="train"
              text="Metro Tweets"
              onClick={() => this.setState({ isDrawerOpened: true })}
            />

            <Button
              className="bp3-minimal"
              icon="document"
              text="News History"
              onClick={() => this.setState({ isNewsOpened: true })}
            />

            <Button
              className="bp3-minimal"
              icon="data-lineage"
              text="Storytelling"
              onClick={() => this.setState({ isStorytellingOpened: true })}
            />

            <Button
                className="bp3-minimal"
                icon="data-lineage"
                text="News Storytelling"
                onClick={() => this.setState({ isStorytellingOpenedNews: true })}
                style={{display:"none"}}
            />

            <Button
                className="bp3-minimal"
                icon="data-lineage"
                text="Admin"
                onClick={() => this.setState({ isKeywordListOpened: true })}
                style={{display: this.state.isUserLogin ? 'block' : 'none' }}
            />

          </NavbarGroup>

          <NavbarGroup align={Alignment.RIGHT}>
            <Button
                  className="bp3-minimal"
                  icon="log-in"
                  text="Login"
                  onClick={() => this.setState({ isLoginPageOpened: true })}
                  style={{display: this.state.isUserLogin ? 'none' : 'block' }}
              />
            <Button
                  className="bp3-minimal"
                  icon="log-out"
                  text="Logout"
                  onClick={() => {
                    fetch(process.env.REACT_APP_DOMAIN + "/api/v1/login/logout")
                      .then((response) => response.json())
                      .then((data) => {
                        if (data.status == 'ok') {
                          this.setState({isUserLogin: false})
                        }else {
                          this.setState({isUserLogin: true})
                        }
                    });
                  }}
                  style={{display: this.state.isUserLogin ? 'block' : 'none' }}
              />
          </NavbarGroup>

        </Navbar>

        <Drawer
          title="Stations"
          {...drawerState}
          onClose={() => this.setState({ isDrawerOpened: false })}
          style={drawerStyle}
        >
          <Tree
            contents={this.state.lineNodes}
            onNodeClick={this.handleNodeClick}
            onNodeCollapse={this.handleNodeCollapse}
            onNodeExpand={this.handleNodeExpand}
          />
        </Drawer>

        <NewsPanel />

        <RealtimeMonitor
          isOpen={this.state.isMonitorOpened}
          toaster={this.toaster}
          onStationClick={this.onStationClick}
          onNewEvent={(event) => {
            this.state.allEvents.unshift(event);
            this.state.stations
              .filter((station) => {
                return (
                  station.Code === event.code ||
                  (event.affectedArea !== undefined &&
                    event.affectedArea.indexOf(station.Code) > -1)
                );
              })
              .forEach((station) => {
                station.stories.unshift(event);
              });

            this.setState({
              filterData: this.state.allEvents,
              displayEvents: this.state.allEvents.slice(0, 10),
            });
          }}
        />

        <Toaster
          {...this.state}
          ref={this.refHandlers.toaster}
          position={Position.BOTTOM_RIGHT}
        />
      </div>
    );

    const mobileLayout = (
      <div>
        <div>
          <div style={{ width: "100%", height: this.state.mapHeight }}>
            <IncidentMap
              stations={this.state.stations}
              onStationClick={this.onStationClick}
              newCenter={this.state.newCenter}
              onUpdate={(events) => this.setState({ center: events })}
            />
          </div>
          {this.state.mobilePage === 1 && (
            <MobileEventList
              allEvents={this.state.allEvents}
              height={this.state.mapHeight}
            />
          )}

          {this.state.mobilePage === 2 && (
            <MobileNewsList
              allEvents={this.state.allNews}
              height={this.state.mapHeight}
            />
          )}
          {this.state.mobilePage === 3 && (
            <MobileStationList
              contents={this.state.lineNodes}
              onNodeClick={this.handleNodeClick}
              onNodeCollapse={this.handleNodeCollapse}
              onNodeExpand={this.handleNodeExpand}
              height={this.state.mapHeight}
            />
          )}
          {this.state.mobilePage === 4 && (
            <UserFeedback height={this.state.mapHeight} />
          )}
          {
            <StationInfo
              stationInfoOpen={this.state.stationInfoOpen}
              stations={this.state.stations}
              selectedStation={this.state.selectedStation}
              onClose={() => {
                this.setState({
                  stationInfoOpen: false,
                  selectedStation: null,
                });
              }}
            />
          }
        </div>

        <div>
          <BottomNavigation
            value={this.state.mobilePage}
            onChange={(event, newValue) => {
              this.setState({
                mobilePage: newValue,
              });
            }}
            showLabels
            id="nav"
            style={{
              width: "100%",
              position: "fixed",
              bottom: 0,
            }}
          >
            <BottomNavigationAction label="Explore" icon={<LocationOn />} />
            <BottomNavigationAction label="Events" icon={<ListAlt />} />
            <BottomNavigationAction label="News" icon={<Description />} />
            <BottomNavigationAction
              label="Stations"
              icon={<DirectionsTransit />}
            />
            <BottomNavigationAction label="Report" icon={<Announcement />} />
          </BottomNavigation>
        </div>
      </div>
    );

    return isMobile(navigator.userAgent).any ? mobileLayout : layout;
  }
}

export default MainPage;
