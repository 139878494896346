import React, { Component } from "react";
import './commonComponent/css/TagsInput.css'
import {Dialog} from "@blueprintjs/core";
import TagsInput from './commonComponent/TagsInput'

class KeywordList extends Component {
    constructor(props) {
        super(props);
        console.log(props)
        this.state = {
            isOpen: this.props.isDialogOpened,
            isInit: false,
            tags: []
        };

    }

    addTag(newTag) {
        this.setState({
            tags: [...this.state.tags, newTag]
        });
    }

    removeTag(removedTag) {
        this.setState({
            tags: this.state.tags.filter(function(tag) {
                return tag !== removedTag;
            })
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        
        // Open
        if (this.state.isOpen === false && this.props.isDialogOpened) {
            this.setState({
                isOpen : true
            })
            return;
        }

        // Close
        if (this.state.isOpen && this.props.isDialogOpened === false) {
            this.setState({
                isOpen : false
            })
            return;
        }
        

        if (
            this.state.isInit &&
            this.state.isOpen
        ) {
            const tags = [];

            fetch(process.env.REACT_APP_DOMAIN + "/api/v1/keyword/")
            .then((response) => response.json())
            .then((data) => {
                data.keywords.forEach( (keyword) => {
                    tags.push(keyword.keyword);
                });

                console.log("TAGS: " + tags);

                this.setState({
                    isInit : false,
                    tags : tags
                });
            });
        }
    }


    render() {
        let dialogStyle = {
            width: "80%",
        };

        let filterGroupStyle = {
            padding: 10,
            paddingLeft: 20,
            backgroundColor: "#FFFFFF",
        };



        return (
            <Dialog
                icon={this.props.icon}
                title={this.props.title}
                isOpen={this.state.isOpen}
                usePortal={true}
                style={dialogStyle}
                onClose={() => {
                    this.props.onClose();
                }}
                onOpened={() => {
                    this.setState({ isInit: true });
                }} 
            >
               <div>
                   <TagsInput 
                    tags={this.state.tags} 
                    addTag={ (newTag) => this.addTag(newTag) }
                    removeTag= { (removedTag) => this.removeTag(removedTag)}
                    >
                   </TagsInput>
               </div>
            </Dialog>
        );
    }
}

export default KeywordList;
