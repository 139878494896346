import React from "react";

const TagsInput = props => {

    
    const removeTags = indexToRemove => {
        if (window.confirm("Do you want to remove this tag")) {
            const val = props.tags[indexToRemove];

            // remove tag
            fetch(process.env.REACT_APP_DOMAIN + "/api/v1/keyword/remove?" + new URLSearchParams({
              keyword: val,
            }))
            .then((response) => response.json())
            .then((data) => {
                    if (data.status === 'ok') {
                        props.removeTag(val);
                    }else if (data.error === 'UNAUTHORIZED'){
                        alert("Error password");
                    }
                });
        }

    };
    
    const addTags = event => {
        if (event.target.value !== "") {
            const val = event.target.value;

            // add tag
            fetch(process.env.REACT_APP_DOMAIN + "/api/v1/keyword/add?" + new URLSearchParams({
              keyword: val,
            }))
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 'ok') {
                    props.addTag(val);   
                }else if (data.error === 'UNAUTHORIZED'){
                    alert("Error password");
                }
            });
            event.target.value = "";
        }
    };

    return (

        <div>
            <div className="tags-input">
                <ul id="tags">
                    {props.tags.map((tag, index) => (
                        <li key={index} className="tag">
                            <span className='tag-title'>{tag}</span>
                            <span className='tag-close-icon'
                                  onClick={() => removeTags(index)}
                            >
                                x
                            </span>
                        </li>
                    ))}
                </ul>
            <input
                type="text"
                onKeyUp={event => event.key === "Enter" ? addTags(event) : null}
                placeholder="Press enter to add keywords"
            />
            </div>
            
        </div>

        
    );
};




export default TagsInput;