import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Timeline } from "antd";

export default class StationInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.stationInfoOpen,
      selectedStation: this.props.selectedStation,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isOpen: nextProps.stationInfoOpen,
      selectedStation: nextProps.selectedStation,
    });
  }

  handleClose = () => {
    this.props.onClose();
  };

  getTitle() {
    if (this.props.stations.length === 0 || this.state.selectedStation === null) {
      return "Station";
    } else {
      return this.props.stations.find(
        (station) => station.Code === this.state.selectedStation
      ).Name;
    }
  }

  render() {

    var station = this.props.stations.find(
      (station) => station.Code === this.state.selectedStation
    );

    var timelineItems = [];
    if (station !== undefined && station.stories.length > 0) {
      timelineItems = station.stories.map((story) => {
        return (
          <Timeline.Item>
            {" "}
            {story.message} {story.date.toLocaleString("en-US")}
          </Timeline.Item>
        );
      });
    } else {
      timelineItems.push(<Timeline.Item> No incidents </Timeline.Item>);
    }

    return (
      <div>
        <Dialog
          open={this.state.isOpen}
          onClose={this.handleClose}
          scroll={"paper"}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{this.getTitle()}</DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText
              id="scroll-dialog-description"
              //   ref={descriptionElementRef}
              tabIndex={-1}
            >
              <Timeline>{timelineItems}</Timeline>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
