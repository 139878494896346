import React, { Component } from "react";

export default class NewsPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }

  componentDidMount() {
    var now = new Date();
    var startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    fetch(
      process.env.REACT_APP_DOMAIN +
        "/api/v1/news?from=" +
        // (startOfDay / 1000 - 60 * 60 * 24 * 7) +
        (startOfDay / 1000 - 60 * 60 * 24 * 20) +
        "&to=" +
        Math.floor(Date.now() / 1000)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ events: data.news.slice(0, 3) });
      });
  }

  getFormatedDate(timestamp) {
    return new Date(parseInt(timestamp)).toLocaleString("en-US");
  }

  render() {
    const windowStyle = {
      marginTop: 10,
      marginRight: 15,
      width: 400,
      position: "relative",
      float: "right",
      background: "rgba(255, 255, 255, 0.8)",
      borderRadius: "3px",
      boxShadow: "inset 0 0 0 1px rgba(16, 22, 26, 0.15)",
      fontSize: "13px",
      padding: "13px 15px 12px",
      overflowY: "auto",
      overflowX: "hidden",
      maxHeight: 450,
    };

    const listItemStyle = {
      margin: "0px 0px 5px 0px",
    }

    const imageListItemStyle = {
      margin: "0px 0px 5px 0px",
      height: "100%",
      width: "100%",
      display: "flex",
    };

    const imageDivStyle = {
      width: "25%",
    };

    const imageStyle = {
      width: "100%",
    };

    const textStyle = {
      width: "75%",
    };

    return (
      <div style={windowStyle}>
        <div>
          <h3>
            <i>News</i>
          </h3>
        </div>
        {this.state.events.map((event, index) =>
          event.urlToImage == null ? (
            <div key={index} style={listItemStyle}>
              <b>{event.title}</b>
              <div>
                {event.description}
                {event.url !== undefined && (
                  <a href={event.url} rel="noopener noreferrer" target="_blank">
                    {" "}
                    Source
                  </a>
                )}
              </div>
            </div>
          ) : (
            <div key={index} style={imageListItemStyle}>
              <div style={textStyle}>
                <b>{event.title}</b>
                <div>
                  {event.description}
                  {event.url !== undefined && (
                    <a
                      href={event.url}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      {" "}
                      Source
                    </a>
                  )}
                </div>
              </div>
              <div style={imageDivStyle}>
                <img src={event.urlToImage} style={imageStyle} />
              </div>
            </div>
          )
        )}
      </div>
    );
  }
}
